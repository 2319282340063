import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import ProfileEditModal from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal';
import Loading from '@hellodarwin/core/lib/components/loading';
import ProviderProfile from '@hellodarwin/core/lib/components/provider/profile';
import ContactPreview from '@hellodarwin/core/lib/components/provider/profile/profile-contact/contact-preview';
import {
  ProfileModalProps,
  Provider,
  TeamMember,
} from '@hellodarwin/core/lib/features/entities';
import ProfileBlocks from '@hellodarwin/core/lib/features/enums/profile-blocks';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/index';
import { fetchCompany } from '../../features/api/slices/companies-slice';
import {
  fetchPortfolios,
  fetchProfileScore,
  fetchTeamMembers,
  selectProfileModal,
  selectProfileScore,
  selectProviderPortfolioById,
  selectProviderPortfolios,
  selectProviderTeamMembers,
  toggleProfileModal,
} from '../../features/api/slices/providers-slice';
import {
  clearCategoriesWithSelectedTags,
  fetchLocalizedCategoriesWithSelectedTags,
  selectCategoriesWithSelectedTags,
} from '../../features/api/slices/tags-slice';
import { selectCompanyContacts } from '../../features/api/slices/users-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

type ProfilePreviewProps = {
  provider: Provider;
};
const ProfilePreview = ({ provider }: ProfilePreviewProps) => {
  const [state, setState] = useState<'edit' | 'client' | 'website'>('edit');
  const { t } = useTranslation();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const contacts = useAppSelector((state) =>
    selectCompanyContacts(state, provider.company_id),
  );

  const categoriesWithSelectedTags = useAppSelector(
    selectCategoriesWithSelectedTags,
  );

  const teamMembers = useAppSelector(selectProviderTeamMembers);
  const portfolios = useAppSelector(selectProviderPortfolios);
  const profileScore = useAppSelector(selectProfileScore);
  const [members, setMembers] = useState<TeamMember[]>(teamMembers);
  const locale = useLocale();

  useEffect(() => {
    if (!provider.provider_id) return;
    dispatch(fetchTeamMembers({ api, provider_id: provider.provider_id }));
    dispatch(fetchCompany({ api, companyId: provider.company_id }));
    dispatch(fetchProfileScore({ api, providerId: provider.provider_id }));
    dispatch(fetchPortfolios({ api, providerId: provider.provider_id }));
  }, [provider, api, dispatch, locale.selectedLocale]);

  //MODAL VARIABLES
  const modal = useAppSelector(selectProfileModal);
  const handleCloseModal = () => {
    dispatch(
      toggleProfileModal({ open: false, targetId: undefined, type: undefined }),
    );
  };
  const toggleEditModal = (props: ProfileModalProps) => {
    dispatch(toggleProfileModal(props));
  };

  const activePortfolio = useAppSelector((state) =>
    selectProviderPortfolioById(state, modal.targetId || ''),
  );

  useEffect(() => {
    dispatch(clearCategoriesWithSelectedTags());

    if (activePortfolio) {
      dispatch(
        fetchLocalizedCategoriesWithSelectedTags({
          api,
          providerId: provider.provider_id,
          locale: locale.selectedLocale,
          additionalTags: activePortfolio.tags,
        }),
      );
    } else {
      dispatch(
        fetchLocalizedCategoriesWithSelectedTags({
          api,
          providerId: provider.provider_id,
          locale: locale.selectedLocale,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePortfolio]);

  const navigate = useNavigate();
  const handleRedirectToSettings = () => {
    navigate(`/providers/${provider.provider_id}/provider`);
  };
  if (!provider.name) return <Loading />;

  return (
    <>
      <Div flex="column" align="center" justify="center">
        <Div fitContent flex="column" gap={48}>
          <Div flex="row">
            <Div flex="column">
              {' '}
              <Typography>
                {'Profile Completion Score: '}
                {profileScore.analytics_profile_completed_percentage}
              </Typography>
            </Div>
            <Div flex="column" align="flex-end">
              <Select
                options={[
                  {
                    label: parse(t('provider-profile|viewTypes.edit')),
                    value: 'edit',
                  },
                  {
                    label: parse(t('provider-profile|viewTypes.client')),
                    value: 'client',
                  },
                  {
                    label: parse(t('provider-profile|viewTypes.website')),
                    value: 'website',
                  },
                ]}
                size="large"
                style={{ width: 250 }}
                onChange={setState}
                value={state}
              />
            </Div>
          </Div>
          <ProviderProfile
            provider={provider}
            state={state}
            portfolios={portfolios}
            teamMembers={members}
            setTeamMembers={setMembers}
            reviews={provider.reviews ?? []}
            toggleEditModal={toggleEditModal}
            handleRedirectToSettings={handleRedirectToSettings}
            handleRedirectToExpertise={handleRedirectToSettings}
            sections={
              state === 'edit'
                ? [
                    ProfileBlocks.FastInfos,
                    ProfileBlocks.About,
                    ProfileBlocks.Presentation,
                    ProfileBlocks.Portfolios,
                    ProfileBlocks.Reviews,
                    ProfileBlocks.Team,
                    ProfileBlocks.Expertise,
                    ProfileBlocks.Contact,
                  ]
                : state === 'website'
                  ? [
                      ProfileBlocks.FastInfos,
                      ProfileBlocks.About,
                      ProfileBlocks.Presentation,
                      ProfileBlocks.Portfolios,
                      ProfileBlocks.Expertise,
                      ProfileBlocks.Reviews,
                      ProfileBlocks.Team,
                      ProfileBlocks.Contact,
                    ]
                  : [
                      ProfileBlocks.FastInfos,
                      ProfileBlocks.Presentation,
                      ProfileBlocks.Portfolios,
                      ProfileBlocks.About,
                      ProfileBlocks.Reviews,
                      ProfileBlocks.Team,
                      ProfileBlocks.Expertise,
                      ProfileBlocks.Contact,
                    ]
            }
          />
        </Div>
        <ProfileEditModal
          modal={modal}
          handleCancel={handleCloseModal}
          categoriesWithSelectedTags={categoriesWithSelectedTags}
          teamMembers={members}
          setTeamMembers={setMembers}
          activePortfolio={activePortfolio}
        />

        {contacts?.[0] && (
          <ContactPreview
            isVisible={showModal}
            onCancel={() => setShowModal(false)}
            logo={provider.logo || ''}
            name={provider.name || ''}
            title={contacts?.[0].title || ''}
            phone={contacts?.[0].phone || ''}
            ext={contacts?.[0].ext || ''}
            email={contacts?.[0].email || ''}
          />
        )}
      </Div>
    </>
  );
};

export default ProfilePreview;
