import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import TextArea from 'antd/es/input/TextArea';
import notification from 'antd/es/notification';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { createTagAdapter } from '../../../../../features/adapters/tag-adapter';
import handleDispatchResponse from '../../../../../features/helpers/handle-dispatch-response';
import { useCompanyInfos } from '../../../../../features/providers/company-infos-provider';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import { Tablet } from '../../../../common/Media';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import { ModalConfirm } from '../../../../common/hd-modal/confirm';
import ModalWithHeaderFooter from '../../../../common/hd-modal/layouts/modal-with-header-footer';
import Typography from '../../../../common/typography';
import Loading from '../../../../loading';
import FormItem from '../../../form-layouts/form-item';
import FormLabel from '../../../form-layouts/form-label';
import PortfolioAssets from './portfolio-assets';
const PortfolioForm = ({ handleCancel, activePortfolio, categoriesWithSelectedTags, }) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const theme = useTheme();
    const { provider } = useCompanyInfos();
    const { handleFooter, functions } = useProfileEdition();
    const [assets, setAssets] = useState([]);
    const [madeWithHelloDarwin, setMadeWithHelloDarwin] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const tagAdapter = createTagAdapter();
    const [initialValues, setInitialValues] = useState({
        portfolio_title: '',
        project_url: '',
        client_company_name: '',
        description: '',
        service: '',
        specialties: [],
    });
    const handleResetAndCancel = () => {
        setAssets([]);
        form.resetFields();
        handleCancel();
    };
    useEffect(() => {
        var _a, _b;
        if (!!activePortfolio) {
            const portfolioTags = tagAdapter.selectPortfolioTags(activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.tags);
            setInitialValues({
                portfolio_title: activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.portfolio_title,
                project_url: activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.project_url,
                client_company_name: activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.client_company_name,
                description: activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.description,
                service: (_a = portfolioTags === null || portfolioTags === void 0 ? void 0 : portfolioTags.services) === null || _a === void 0 ? void 0 : _a[0],
                specialties: portfolioTags === null || portfolioTags === void 0 ? void 0 : portfolioTags.specialties,
            });
            setMadeWithHelloDarwin(!!(activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.from_hellodarwin_at));
            setIsPublic(!!(activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.public_at));
            setAssets((_b = activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.assets) !== null && _b !== void 0 ? _b : []);
        }
        else {
            setInitialValues({
                portfolio_title: '',
                project_url: '',
                client_company_name: '',
                description: '',
                service: '',
                specialties: [],
            });
            setAssets([]);
            setMadeWithHelloDarwin(false);
            setIsPublic(false);
        }
    }, [activePortfolio]);
    const handleSave = () => {
        form === null || form === void 0 ? void 0 : form.validateFields().then(async (values) => {
            const tags = [];
            if ((values === null || values === void 0 ? void 0 : values.specialties) !== undefined)
                tags.push(...values === null || values === void 0 ? void 0 : values.specialties);
            tags.push(values === null || values === void 0 ? void 0 : values.service);
            const data = new FormData();
            let formPortfolio = {
                provider_id: provider === null || provider === void 0 ? void 0 : provider.provider_id,
                description: values === null || values === void 0 ? void 0 : values.description,
                project_url: values === null || values === void 0 ? void 0 : values.project_url,
                portfolio_title: values === null || values === void 0 ? void 0 : values.portfolio_title,
                client_company_name: values === null || values === void 0 ? void 0 : values.client_company_name,
                made_with_hellodarwin: madeWithHelloDarwin,
                public: isPublic,
                tags,
            };
            if (!!(activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.provider_portfolio_id)) {
                formPortfolio['provider_portfolio_id'] =
                    activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.provider_portfolio_id;
            }
            data.append('request', JSON.stringify(formPortfolio));
            data.append('assets', JSON.stringify(assets));
            assets.forEach((asset) => {
                if (!!(asset === null || asset === void 0 ? void 0 : asset.file) && !!(asset === null || asset === void 0 ? void 0 : asset.filename)) {
                    data.append(asset === null || asset === void 0 ? void 0 : asset.filename, asset === null || asset === void 0 ? void 0 : asset.file);
                }
            });
            if (!!(activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.provider_portfolio_id)) {
                !!functions.updatePortfolio &&
                    (await functions
                        .updatePortfolio({ data })
                        .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
            }
            else {
                !!functions.submitPortfolio &&
                    (await functions
                        .submitPortfolio({ data })
                        .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error'))));
            }
            handleResetAndCancel();
        }).catch((error) => notification.error({
            message: error.errorFields[0].errors[0],
            placement: 'top',
        }));
    };
    const handleDelete = () => {
        ModalConfirm({
            title: t('profile|assetsModal.confirm'),
            content: t('profile|assetsModal.delete.portfolio'),
            okText: t('profile|assetsModal.confirm'),
            cancelText: t('profile|assetsModal.cancel'),
            okButtonProps: {
                style: {
                    backgroundColor: theme.colors.purple_1,
                    color: theme.colors.white_1,
                    borderRadius: '24px',
                },
            },
            cancelButtonProps: {
                style: {
                    backgroundColor: theme.colors.white_1,
                    borderColor: theme.colors.grey_1,
                    color: theme.colors.grey_1,
                    borderRadius: '24px',
                },
            },
            onOk: async () => {
                functions
                    .removePortfolio({
                    portfolioID: activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.provider_portfolio_id,
                })
                    .then((res) => handleDispatchResponse(res, t('notification.saved'), t('notification.saving_error')));
            },
        });
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.nextSibling.focus();
        }
    };
    const Checkboxes = () => (_jsxs(Div, { backgroundColor: theme.colors.white_1, borderRadius: 12, borderColor: theme.colors.grey_4, align: "flex-start", style: { padding: '0.5rem' }, fitMaxContent: true, flex: "row", gap: 10, children: [_jsx(Checkbox, { onChange: () => setMadeWithHelloDarwin(!madeWithHelloDarwin), checked: madeWithHelloDarwin, children: _jsx(Typography, { nowrap: true, elementTheme: "caption", medium: true, children: t('portfolio|form.label.madeWithHelloDarwin') }) }), _jsx(Checkbox, { onChange: () => setIsPublic(!isPublic), checked: isPublic, children: _jsx(Typography, { nowrap: true, elementTheme: "caption", medium: true, children: t('portfolio|form.label.public') }) })] }));
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);
    useEffect(() => {
        handleFooter(_jsx(ModalWithHeaderFooter.Footer, { actions: _jsxs(_Fragment, { children: [_jsx(Tablet, { direction: "up", children: _jsx(Checkboxes, {}) }), _jsxs(Div, { flex: "row", align: "center", justify: "flex-end", gap: 16, tablet: { flex: 'column-reverse' }, children: [(activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.provider_portfolio_id) ? (_jsx(Button, { defaultStyle: theme.colors.red_1, onClick: handleDelete, size: "small", children: t('button.delete') })) : (_jsx(Button, { defaultStyle: theme.colors.white_1, onClick: handleResetAndCancel, size: "small", children: t('button.cancel') })), _jsx(Button, { defaultStyle: theme.colors.purple_1, onClick: handleSave, size: "small", children: t('button.save') })] })] }) }));
    }, [isPublic, madeWithHelloDarwin, activePortfolio, assets]);
    if (categoriesWithSelectedTags == null)
        return _jsx(Loading, {});
    return (_jsxs(Div, { tablet: { flex: 'column' }, gap: 16, children: [_jsx(Form, { layout: 'vertical', onFinish: handleSave, form: form, initialValues: initialValues, children: _jsxs(Div, { flex: "row", gap: 32, tablet: { flex: 'column-reverse', gap: 16 }, children: [_jsxs(Div, { flex: "column", gap: 16, style: { flex: 1 }, children: [_jsx(FormItem, { className: 'hd-0-mb', name: "portfolio_title", label: _jsx(FormLabel, { label: t('portfolio|form.label.title') }), children: _jsx(Input, { size: 'large', placeholder: t('portfolio|form.placeholder.title'), onKeyDown: handleKeyDown }) }), _jsx(FormItem, { className: 'hd-0-mb', name: "project_url", label: _jsx(FormLabel, { label: t('portfolio|form.label.projectURL') }), children: _jsx(Input, { size: 'large', placeholder: t('portfolio|form.placeholder.projectURL'), onKeyDown: handleKeyDown }) }), _jsx(FormItem, { className: 'hd-0-mb', name: "client_company_name", label: _jsx(FormLabel, { label: t('portfolio|form.label.clientName') }), children: _jsx(Input, { size: 'large', placeholder: t('portfolio|form.placeholder.clientName'), onKeyDown: handleKeyDown }) }), _jsx(FormItem, { className: 'hd-0-mb', name: "description", label: _jsx(FormLabel, { label: t('portfolio|form.label.description') }), children: _jsx(TextArea, { className: "text-editor" }) })] }), _jsxs(Div, { flex: "column", gap: 16, style: { flex: 1 }, children: [_jsx(PortfolioAssets, { assets: assets, setAssets: setAssets, portfolio_id: (activePortfolio === null || activePortfolio === void 0 ? void 0 : activePortfolio.provider_portfolio_id) || '' }), _jsx(FormItem, { name: "service", label: _jsx(FormLabel, { label: t('portfolio|form.label.service') }), style: { width: '100%' }, rules: [
                                        {
                                            required: true,
                                            message: t('portfolio|form.validation.service'),
                                        },
                                    ], children: _jsx(Select, { size: 'large', placeholder: t('portfolio|form.placeholder.service'), showSearch: true, notFoundContent: null, children: Object.entries(categoriesWithSelectedTags).map(([category, content]) => (_jsx(Select.OptGroup, { label: category, children: content.services.map((service) => (_jsx(Select.Option, { value: service.full_tag, children: service.label }, service.full_tag))) }, category))) }) }), _jsx(FormItem, { name: "specialties", label: _jsx(FormLabel, { label: t('portfolio|form.label.specialties'), extra: t('portfolio|form.disclaimer.specialties') }), style: { width: '100%' }, rules: [
                                        {
                                            required: true,
                                            message: t('portfolio|form.validation.specialties'),
                                        },
                                    ], children: _jsx(Select, { size: 'large', placeholder: t('portfolio|form.placeholder.specialties'), showSearch: true, notFoundContent: null, mode: 'multiple', children: Object.entries(categoriesWithSelectedTags).map(([category, content]) => (_jsx(Select.OptGroup, { label: category, children: content.specialties.map((specialty) => (_jsx(Select.Option, { value: specialty.full_tag, children: specialty.label }, specialty.full_tag))) }, category))) }) })] })] }) }), _jsx(Tablet, { direction: "down", children: _jsx(Checkboxes, {}) })] }));
};
export default PortfolioForm;
