import {
  BankOutlined,
  CreditCardOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import ProfileUsersList from '@hellodarwin/core/lib/components/profile/profile-users-list';
import {
  Contact,
  InitialAdminSelectedTags,
  Provider,
  ProviderRequest,
} from '@hellodarwin/core/lib/features/entities';
import {
  getFormattedDate,
  getShortFormattedDate,
} from '@hellodarwin/core/lib/features/helpers';
import CompanyInfosProvider from '@hellodarwin/core/lib/features/providers/company-infos-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';
import { TabsProps } from 'antd/es/tabs';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import Auth0UserButtons from '../../components/auth0/contacts-auth0-buttons';
import BlockButton from '../../components/blocks/block-button';
import BlocksList from '../../components/blocks/blocks-list';
import MatchesBlock from '../../components/blocks/matches-block';
import Clipboard from '../../components/clipboard';
import ProviderForm from '../../components/providers/provider-form';
import ProviderStatusForm from '../../components/providers/provider-status-form';
import { EMAIL_ALREADY_EXISTS_ERROR } from '../../components/rfp/rfp-action-modal/create-rfp-form';
import SubscriptionForm from '../../components/subscription/subscription-form';
import SubscriptionPreview from '../../components/subscription/subscription-preview';
import TagsForm, { EntityType } from '../../components/tags/tags-form';
import ExternalLink from '../../components/widgets/external-link';
import { useActionBar } from '../../context/action-bar-provider';
import {
  getCreditBalance,
  selectBillingIsLoading,
  selectCreditBalance,
} from '../../features/api/slices/billing-slice';
import {
  fetchCompany,
  selectCompanyById,
} from '../../features/api/slices/companies-slice';
import {
  fetchProviderById,
  fetchProviderTags,
  fetchPurchasedMatches,
  selectProviderPurchasedMatches,
  selectProvidersIsErrored,
  selectSelectedProvider,
  updateProvider,
  updateProviderTags,
} from '../../features/api/slices/providers-slice';
import { fetchTags, selectAllTags } from '../../features/api/slices/tags-slice';
import {
  assignContact,
  createContact,
  deleteContact,
  fetchCompanyContacts,
  getContactByEmail,
  selectCompanyContacts,
  updateContact,
} from '../../features/api/slices/users-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import {
  BlockContent,
  toggleBlockFunction,
  useBlocks,
} from '../../hooks/use-blocks';
import { AdminPagesForms } from '../single-rfp-page';
import AvailableRfpList from './available-rfp-list';
import ProfilePreview from './profile-preview';
import ReferredRfpList from './referred-rfp-list';
import RefusedRfpList from './refused-rfp-list';

const { Title } = Typography;

const blocksInitialState: BlockContent[] = [
  {
    element: <ProviderForm setForms={() => null} />,
    icon: <BankOutlined />,
    label: '',
    visible: true,
    unique: false,
  },
  {
    element: (
      <>
        <TagsForm
          entityType={EntityType.Provider}
          tags={InitialAdminSelectedTags}
        />
        <SubscriptionForm billingId={''} creditBalance={0} />
      </>
    ),
    icon: <CreditCardOutlined />,
    label: '',
    visible: true,
    unique: false,
  },
  {
    element: <MatchesBlock matches={[]} targetId={''} />,
    icon: <TeamOutlined />,
    label: '',
    visible: false,
    unique: true,
  },
];

export enum ProviderContent {
  Provider = 'provider',
  AvailableRfp = 'available_rfp',
  RefusedRfp = 'refused_rfp',
  Referrals = 'referred_rfp',
  Profile = 'profile',
  Users = 'contacts',
}

const TabsContent = ({
  tab,
  providerId,
  blocks,
  span,
  provider,
  companyContacts,
  activeTab,
  toggleBlock,
}: {
  tab: ProviderContent;
  providerId?: string;
  blocks: BlockContent[];
  span: number;
  provider: Provider;
  companyContacts: Contact[];
  activeTab: ProviderContent;
  toggleBlock: toggleBlockFunction;
}) => {
  const { t } = useTranslations();
  const BlockButtons = () => {
    let element = <></>;
    switch (activeTab) {
      case ProviderContent.Provider:
        element = (
          <Div flex="row" gap={6}>
            {blocks.map((block, i) => (
              <BlockButton
                key={i}
                icon={block.icon}
                onClick={() => toggleBlock(i)}
                tooltip={block.label}
                active={block.visible}
                notification={block.notification}
              />
            ))}
          </Div>
        );
        break;
    }
    return element;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const handleContactDelete = async (contactId: string) => {
    return await dispatch(deleteContact({ api, contactId }));
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: 'Partner',
      }),
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        Modal.confirm({
          title: t('provider_details|contact_exists_modal_title'),
          icon: <ExclamationCircleOutlined />,
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: '24px',
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: '24px',
            },
          },
          content: t('provider_details|contact_exists_modal_content'),
          async onOk() {
            contact.company_id = provider.company_id;
            return await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel() {},
        });
      } else {
        message.error(t('provider_details|messageError'));
      }
    }
    return createContactResult;
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = provider.company_id;
    return await dispatch(assignContact({ api, contact: contact }));
  };

  const handleEmailExists = async (email: string) => {
    if (companyContacts.find((c) => c.email === email)) {
      return Promise.reject(t('provider_details|emailExists'));
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  if (providerId) {
    switch (tab) {
      case ProviderContent.Provider:
        return (
          <BlocksList blocks={blocks} actions={<BlockButtons />} span={span} />
        );
      case ProviderContent.AvailableRfp:
        return (
          <div style={{ width: '100%' }}>
            <Title level={3}>{t('provider_details|availableRfps')}</Title>
            <AvailableRfpList providerId={providerId} />
          </div>
        );
      case ProviderContent.RefusedRfp:
        return (
          <div style={{ width: '100%' }}>
            <Title level={3}>{t('provider_details|refusedRfps')}</Title>
            <RefusedRfpList providerId={providerId} />
          </div>
        );
      case ProviderContent.Referrals:
        return (
          <div style={{ width: '100%' }}>
            <ReferredRfpList providerId={providerId} />
          </div>
        );
      case ProviderContent.Profile:
        return (
          <div style={{ width: '100%' }}>
            <ProfilePreview provider={provider} />
          </div>
        );
      case ProviderContent.Users:
        return (
          <div style={{ width: '100%' }}>
            {provider.company_id !== '' && (
              <ProfileUsersList
                users={companyContacts}
                contactId={undefined}
                companyId={provider.company_id}
                handleContactDelete={handleContactDelete}
                handleContactEdit={handleContactEdit}
                handleContactAdd={handleContactAdd}
                handleContactAssign={handleContactAssign}
                handleEmailExists={handleEmailExists}
                Auth0Buttons={Auth0UserButtons}
                isAdmin
                destination={'Partner'}
                ClipboardComponent={({ copy, title }) => (
                  <Clipboard copy={copy} title={title} />
                )}
              />
            )}
          </div>
        );
    }
  }
  return <></>;
};

const SingleProviderPage = () => {
  const { setActionBar } = useActionBar();

  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [blocks, setBlock, toggleBlock, span] = useBlocks(blocksInitialState);
  const { t } = useTranslations();
  const { providerId, defaultSection } = useParams<{
    providerId: string;
    defaultSection: ProviderContent;
  }>();
  const [activeTab, setActiveTab] = useState<ProviderContent>(
    defaultSection ?? ProviderContent.Provider,
  );

  const isLoading = useAppSelector(selectProvidersIsErrored);
  const provider = useAppSelector(selectSelectedProvider);
  const company = useAppSelector((state) =>
    selectCompanyById(state, provider.company_id),
  );
  const purchasedMatches = useAppSelector(selectProviderPurchasedMatches);
  const creditBalance = useAppSelector(selectCreditBalance);
  const billingLoading = useAppSelector(selectBillingIsLoading);
  const tags = useAppSelector(selectAllTags);

  const companyContacts = useAppSelector((state) =>
    selectCompanyContacts(state, provider.company_id),
  );

  const [forms, setForms] = useState<AdminPagesForms>({
    providerForm: undefined,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!!defaultSection) {
      if (defaultSection !== activeTab) {
        setActiveTab(defaultSection);
      }
    } else {
      setActiveTab(ProviderContent.Provider);
    }
  }, [defaultSection, activeTab]);

  useEffect(() => {
    if (!providerId) return;
    dispatch(fetchProviderById({ api, providerId }));
    dispatch(fetchPurchasedMatches({ api, providerId }));

    dispatch(fetchTags({ api })).then((res) => {
      dispatch(fetchProviderTags({ api, providerId }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    if (provider?.company_id && provider.billing_customer_id !== '') {
      dispatch(
        getCreditBalance({
          api,
          billingId: provider.billing_customer_id || '',
        }),
      );
      dispatch(fetchCompany({ api, companyId: provider.company_id }));
      dispatch(fetchCompanyContacts({ api, companyId: provider.company_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useEffect(() => {
    setBlock(
      1,
      <>
        <TagsForm entityType={EntityType.Provider} tags={tags} />
        <SubscriptionPreview
          provider={provider}
          creditBalance={creditBalance}
          loading={billingLoading}
        />
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditBalance, provider, tags]);

  const handleTabClick = (key: ProviderContent) => {
    setActiveTab(key);
    if (providerId)
      navigate(`/providers/${providerId}/${key}`, {
        replace: true,
      });
  };

  const tabs = useMemo(() => {
    let tabs: TabsProps['items'] = [];

    (Object.keys(ProviderContent) as (keyof typeof ProviderContent)[]).forEach(
      (key) => {
        tabs?.push({
          label: key,
          key: ProviderContent[key],
          children: (
            <TabsContent
              tab={ProviderContent[key]}
              providerId={providerId}
              blocks={blocks}
              span={span}
              provider={provider}
              companyContacts={companyContacts}
              activeTab={activeTab}
              toggleBlock={toggleBlock}
            />
          ),
        });
      },
    );

    return tabs;
  }, [
    providerId,
    provider,
    companyContacts,
    blocks,
    activeTab,
    span,
    toggleBlock,
  ]);

  useEffect(() => {
    if (provider.provider_id === providerId) {
      setBlock(0, <ProviderForm provider={provider} setForms={setForms} />);
    }
    setActionBar(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, providerId]);

  useEffect(() => {
    if (provider.provider_id === providerId) {
      setBlock(
        2,
        <MatchesBlock matches={[]} targetId={providerId} forProvider={true} />,
        purchasedMatches.length === 0
          ? undefined
          : { count: purchasedMatches.length },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, providerId, purchasedMatches.length]);

  const onSave = async () => {
    try {
      const providerFormValues = forms.providerForm?.getFieldsValue()!;

      const updatedProviderValues: ProviderRequest = {
        ...provider,
        ...providerFormValues,
      };

      await dispatch(updateProvider({ api, request: updatedProviderValues }));
      await dispatch(
        updateProviderTags({
          api,
          rfpId: provider.provider_id,
          serviceTags: tags.selectedServices,
          specialtyTags: tags.selectedSpecialties,
          industryTag: tags.selectedIndustry,
        }),
      );

      message.success('Saved!');
    } catch (e: any) {
      if (e.response.data.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: `Email already in use!`,
          description:
            'Please use a new email or search for an existing client.',
          placement: 'bottomLeft',
        });
        return;
      }

      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const Actions = () => {
    let element = <></>;

    if (activeTab === ProviderContent.Provider) {
      element = (
        <Div
          flex="row"
          align="center"
          gap={32}
          justify="space-between"
          style={{ width: '100%' }}
        >
          <Div flex="row" align="center" gap={16} fitContent>
            <ProviderStatusForm
              providerId={provider.provider_id}
              status={provider.status!}
            />
            <Button onClick={onSave}>{t('button|save')}</Button>
          </Div>
          <Div flex="row" gap={20} align="center" fitContent>
            {provider.verified_at && (
              <Tooltip title={getFormattedDate(provider.verified_at)}>
                <Tag>
                  Verified: {getShortFormattedDate(provider.verified_at)}{' '}
                </Tag>
              </Tooltip>
            )}
            {provider.created_at && (
              <Tooltip title={getFormattedDate(provider.created_at)}>
                <Tag>
                  {t('provider_details|created_on')}{' '}
                  {getShortFormattedDate(provider.created_at)}{' '}
                </Tag>
              </Tooltip>
            )}
            <Typography
              color={theme.colors.purple_2}
              textAlign="center"
              nowrap
              ellipsis
              onClick={() => {
                window.open(`/companies/${provider?.company_id}`, '_blank');
              }}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
            >
              {t('provider_details|company_profile')}
            </Typography>
            <a
              href={provider.profile_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('provider_details|website')}
            </a>
            <ExternalLink
              id={provider.crm_id}
              title="Hubspot ID"
              url={provider.hubspot_url}
              onEnd={(newValue) => {
                dispatch(
                  updateProvider({
                    api,
                    request: {
                      ...provider,
                      ...{ crm_id: newValue },
                    },
                  }),
                );
              }}
            />
          </Div>
        </Div>
      );
    }

    return element;
  };

  if (isLoading || provider.provider_id !== providerId) return <Loading />;

  return (
    <CompanyInfosProvider
      company={company}
      contacts={companyContacts}
      provider={provider}
    >
      <PageLayout
        tabTitle={provider.name}
        app="admin"
        styles={{
          content: { paddingTop: 0 },
          heading: { actions: { width: '100%' } },
        }}
        title={
          <Clipboard
            copy={provider?.provider_id ?? ''}
            tooltip={'Copy ID to clipboard'}
            title={provider?.name ?? 'New Provider'}
          />
        }
        actions={<Actions />}
        breadcrumbs={[
          {
            breadcrumbName: 'Home',
            path: '/',
          },
          {
            breadcrumbName: t('provider_page|providers'),
            path: '/providers',
          },
          {
            breadcrumbName: provider?.name,
          },
        ]}
      >
        <Container
          tabs={tabs}
          activeTab={activeTab}
          handleTabClick={(activeTab) =>
            handleTabClick(activeTab as ProviderContent)
          }
          fullWidth
        />
      </PageLayout>
    </CompanyInfosProvider>
  );
};

export default SingleProviderPage;
