import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  GrantStep,
  ProgramGrantStatus,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Button from 'antd/es/button';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Popconfirm from 'antd/es/popconfirm';
import Select from 'antd/es/select';
import Skeleton from 'antd/es/skeleton';
import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../../../app';
import {
  selectIsDirty,
  selectIsLoadingSolo,
  setIsGrantStepDirty,
  translateStep,
  updateGrantStep,
} from '../../../../features/api/slices/grant-steps-slice';
import { useNewAdminApi } from '../../../../features/api/use-admin-api';
import theme from '../../../../theme';
import { transformSectionIntoLabel } from '../grant-form';
import GrantFormTranslateButtons from '../grant-form-translate-buttons';

type GrantFormStepProps = {
  grantStep: GrantStep;
  index: number;
  handleDeleteGrantStep: (index: number, id: string) => void;
  onChangeStep: (grantStep: GrantStep) => void;
  isAllGenerated: boolean;
};

const GrantFormStep = ({
  grantStep,
  index,
  handleDeleteGrantStep,
  onChangeStep,
  isAllGenerated,
}: GrantFormStepProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const newApi = useNewAdminApi();
  const [titleEn, setTitleEn] = useState(grantStep.title_en);
  const [titleFr, setTitleFr] = useState(grantStep.title_fr);
  const [descriptionEn, setDescriptionEn] = useState(grantStep.description_en);
  const [descriptionFr, setDescriptionFr] = useState(grantStep.description_fr);
  const [type, setType] = useState<ProgramGrantStatus | undefined>(
    grantStep.type,
  );
  const isLoadingTitleEn = useAppSelector((state) =>
    selectIsLoadingSolo(state, grantStep.grant_step_id, 'title_en'),
  );
  const isLoadingTitleFr = useAppSelector((state) =>
    selectIsLoadingSolo(state, grantStep.grant_step_id, 'title_fr'),
  );
  const isLoadingDescriptionEn = useAppSelector((state) =>
    selectIsLoadingSolo(state, grantStep.grant_step_id, 'description_en'),
  );
  const isLoadingDescriptionFr = useAppSelector((state) =>
    selectIsLoadingSolo(state, grantStep.grant_step_id, 'description_fr'),
  );

  const isDirty = useAppSelector((state: RootState) =>
    selectIsDirty(state, grantStep.grant_step_id),
  );
  useEffect(() => {
    setTitleEn(grantStep.title_en);
    setTitleFr(grantStep.title_fr);
    setDescriptionEn(grantStep.description_en);
    setDescriptionFr(grantStep.description_fr);
    setType(grantStep.type);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantStep.grant_step_id]);

  useEffect(() => {
    handleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleEn, titleFr, descriptionEn, descriptionFr, type]);

  const saveStep = async () => {
    try {
      const grantStepUpdate: GrantStep = {
        ...grantStep,
        title_en: titleEn,
        title_fr: titleFr,
        description_en: descriptionEn,
        description_fr: descriptionFr,
        type: type,
      };
      await dispatch(
        updateGrantStep({ api: newApi, grantStep: grantStepUpdate }),
      );

      message.success(t('grant_form|grant_step_save_success'));
    } catch (error) {
      console.error(error);
      message.error('grant_form|grant_step_save_fail');
    }
  };

  const setIsDirty = () => {
    if (!isDirty) {
      dispatch(
        setIsGrantStepDirty({
          grantStepId: grantStep.grant_step_id,
        }),
      );
    }
  };

  const setField = (section: keyof GrantStep, value: string) => {
    switch (section) {
      case 'title_en':
        setTitleEn(value);
        break;
      case 'title_fr':
        setTitleFr(value);
        break;
      case 'description_en':
        setDescriptionEn(value);
        break;
      case 'description_fr':
        setDescriptionFr(value);
        break;
      case 'type':
        const type: ProgramGrantStatus | undefined = (
          Object.values(ProgramGrantStatus) as string[]
        ).includes(value)
          ? (value as ProgramGrantStatus)
          : undefined;

        setType(type);
        break;
      default:
        break;
    }
  };

  const generateSectionFromTranslation = async (
    sections: (keyof GrantStep)[],
    otherContents: string[],
    targetLocale: string,
  ) => {
    if (sections.length === otherContents.length) {
      for (var i = 0; i < sections.length; i++) {
        generateSingleSectionFromTranslation(
          sections[i],
          otherContents[i],
          targetLocale,
        );
      }
    }
  };

  const generateSingleSectionFromTranslation = async (
    section: keyof GrantStep,
    otherContent: string,
    targetLocale: string,
  ) => {
    try {
      const response = await dispatch(
        translateStep({
          api: newApi,
          stepID: grantStep.grant_step_id,
          targetLocale,
          section: section,
          otherContent: otherContent,
        }),
      ).unwrap();
      const titleLabel = transformSectionIntoLabel(response.section);
      if (response.newContent === '') {
        message.info(
          `No answer for ${titleLabel}. Either try again, change the prompt or add some context.`,
        );
      } else {
        setField(response.section, response.newContent);

        message.success(
          `Section ${titleLabel} translated! Don't forget to save.`,
        );
      }
    } catch (e: any) {
      message.error('Error !');
      message.error(e.cause);
    }
  };

  const applicationOptionsStatus = Object.entries(ProgramGrantStatus).map(
    ([key, value]) => ({
      key: key,
      value: value,
      label: t(`programs_labels|${value}`),
    }),
  );

  const handleChange = () => {
    const grantStepUpdate: GrantStep = {
      ...grantStep,
      title_en: titleEn,
      title_fr: titleFr,
      description_en: descriptionEn,
      description_fr: descriptionFr,
      type: type,
    };

    onChangeStep(grantStepUpdate);
  };

  return (
    <Div
      flex="column"
      style={{
        marginBottom: 32,
        paddingRight: 32,
        backgroundColor: theme.colors.grey_5,
        borderRadius: 8,
        padding: 16,
      }}
      key={index}
    >
      <Div flex="row" justify="space-between" style={{ paddingBottom: '16px' }}>
        <Typography elementTheme="h5" style={{ width: 'fit-content' }}>
          {index + 1}
        </Typography>
        <Div flex="row" gap={8} style={{ width: 'fit-content' }}>
          <Select
            onSelect={(value) => {
              setIsDirty();
              setType(value);
            }}
            placeholder={t('grant_form|selectGrantStepType')}
            value={type}
            style={{ width: '300px' }}
            options={applicationOptionsStatus}
          />
          <Button disabled={!isDirty || isAllGenerated} onClick={saveStep}>
            {t('grant_form|save')}
          </Button>
          <Popconfirm
            title={t('grant_form|delete_grant_step')}
            description={t('grant_form|delete_grant_step_long')}
            onConfirm={() =>
              handleDeleteGrantStep(index, grantStep.grant_step_id)
            }
            okText={t('grant_form|delete')}
            cancelText={t('grant_form|no')}
          >
            <Button
              type="primary"
              style={{ padding: 8 }}
              danger={true}
              icon={<Delete size={14} />}
            >
              {t('grant_form|delete')}
            </Button>
          </Popconfirm>
        </Div>
      </Div>
      <Row gutter={16}>
        <Col span={11}>
          <Div flex="column" gap={8}>
            <Typography elementTheme="body2">Title EN</Typography>
            {isLoadingTitleEn ? (
              <Skeleton
                style={{
                  backgroundColor: theme.colors.white_1,
                }}
                active
              />
            ) : (
              <Input
                value={titleEn}
                onChange={(e) => {
                  setIsDirty();
                  setTitleEn(e.target.value);
                }}
              />
            )}
            <Div flex="column" gap={8}>
              <Typography elementTheme="body2">Description EN</Typography>
              {isLoadingDescriptionEn ? (
                <Skeleton
                  style={{
                    backgroundColor: theme.colors.white_1,
                  }}
                  active
                />
              ) : (
                <RichTextEditor
                  value={descriptionEn}
                  onChange={(newValue) => {
                    setIsDirty();
                    setDescriptionEn(newValue);
                  }}
                  minRows={3}
                />
              )}
            </Div>
          </Div>
        </Col>
        <GrantFormTranslateButtons
          generateSectionFromTranslation={(
            sections,
            otherContents,
            targetLocale,
          ) => {
            generateSectionFromTranslation(
              sections as (keyof GrantStep)[],
              otherContents,
              targetLocale,
            );
          }}
          contentEn={[titleEn!, descriptionEn!]}
          contentFr={[titleFr!, descriptionFr!]}
          sectionEn={['title_en', 'description_en']}
          sectionFr={['title_fr', 'description_fr']}
        />
        <Col span={11}>
          <Div flex="column" gap={8}>
            <Typography elementTheme="body2">Title FR</Typography>
            {isLoadingTitleFr ? (
              <Skeleton
                style={{
                  backgroundColor: theme.colors.white_1,
                }}
                active
              />
            ) : (
              <Input
                value={titleFr}
                onChange={(e) => {
                  setIsDirty();
                  setTitleFr(e.target.value);
                }}
              />
            )}
            <Div flex="column" gap={8}>
              <Typography elementTheme="body2">Description FR</Typography>
              {isLoadingDescriptionFr ? (
                <Skeleton
                  style={{
                    backgroundColor: theme.colors.white_1,
                  }}
                  active
                />
              ) : (
                <RichTextEditor
                  value={descriptionFr}
                  onChange={(newValue) => {
                    setIsDirty();
                    setDescriptionFr(newValue);
                  }}
                  minRows={3}
                />
              )}
            </Div>
          </Div>
        </Col>
      </Row>
    </Div>
  );
};

export default GrantFormStep;
