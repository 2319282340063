import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import { useProfileEdition } from '../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../plugins/i18n';
import ModalWithHeaderFooter from '../../../common/hd-modal/layouts/modal-with-header-footer';
import useProfileEditModalBlocks from './hooks/use-profile-edit-modal-blocks';
const ProfileEditModal = (props) => {
    const { t } = useTranslation();
    const { modal: { open, type }, handleCancel, } = props;
    const { footer } = useProfileEdition();
    const blockProps = useProfileEditModalBlocks(props);
    const getModalTitle = () => {
        if (props.modal.type === ProfileBlocks.Portfolios &&
            props.activePortfolio == null) {
            return t(`provider-profile|${ProfileBlocks.Portfolios}.add.title`);
        }
        else {
            return t(`provider-profile|${type}.edit.title`);
        }
    };
    if (!blockProps)
        return _jsx(_Fragment, {});
    const { Component, additionalProps, size } = blockProps;
    return (_jsx(ModalWithHeaderFooter, { open: open, handleCancel: handleCancel, size: size, header: _jsx(ModalWithHeaderFooter.Header, { title: getModalTitle() }), footer: footer, noPadding: [
            ProfileBlocks.Team,
            ProfileBlocks.Logo,
            ProfileBlocks.Presentation,
            ProfileBlocks.Banner,
            undefined,
        ].includes(type), children: _jsx(Component, Object.assign({ handleCancel: handleCancel }, additionalProps)) }));
};
export default ProfileEditModal;
