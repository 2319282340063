import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  AdminGrantResult,
  GrantStep,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import AntdButton from 'antd/es/button';
import Form from 'antd/es/form';
import message from 'antd/es/message';
import Popconfirm from 'antd/es/popconfirm';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../../../app';
import {
  createGrantStep,
  deleteAllGrantSteps,
  deleteGrantStep,
  overwriteGrantSteps,
  reorderGrantSteps,
  selectIsAllGenerated,
  selectIsSomeDirty,
  updateGrantSteps,
} from '../../../../features/api/slices/grant-steps-slice';
import {
  generateGrantSteps,
  selectIsLoadingSoloGen,
} from '../../../../features/api/slices/grants-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../../features/api/use-admin-api';
import GrantFormStep from './grant-form-step';
import GrantFormStepsDrawer from './grant-form-steps-drawer';

type GrantFormStepsProps = {
  grant?: AdminGrantResult;
  isSavingSteps?: boolean;
  setIsSavingSteps?: Dispatch<SetStateAction<boolean>>;
};

export const grantStepsTitle = 'grantSteps';
const GrantFormSteps = ({
  grant,
  isSavingSteps,
  setIsSavingSteps,
}: GrantFormStepsProps) => {
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [grantSteps, setGrantSteps] = useState<GrantStep[]>(
    grant?.grant_steps ?? [],
  );
  const [saveAllFlag, setSaveAllFlag] = useState(false);
  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const isSomeDirty = useAppSelector((state: RootState) =>
    selectIsSomeDirty(state),
  );
  const isAllGenerated = useAppSelector((state: RootState) =>
    selectIsAllGenerated(state),
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslations();
  const isGeneratingSteps = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, grantStepsTitle),
  );

  useEffect(() => {
    if (isSavingSteps && (isSomeDirty || isAllGenerated)) {
      setSaveAllFlag(true);
    }
    setIsSavingSteps && setIsSavingSteps(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingSteps]);

  useEffect(
    () => {
      if (!isSomeDirty && !isAllGenerated && saveAllFlag) {
        setSaveAllFlag(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSomeDirty, isAllGenerated],
  );

  const saveAllSteps = async () => {
    if (isAllGenerated) {
      try {
        await dispatch(
          overwriteGrantSteps({
            api: newApi,
            grantSteps,
          }),
        );
        message.success(t('grant_form|grantStepsOverwriteSuccess'));
      } catch (e: any) {
        console.error(e);
        message.error(t('grant_form|grantStepsOverwriteFail'));
      }
    } else {
      try {
        await dispatch(
          updateGrantSteps({
            api: newApi,
            grantSteps,
          }),
        );
        message.success(t('grant_form|grantStepsSaveAllSuccess'));
      } catch (e: any) {
        console.error(e);
        message.error(t('grant_form|grantStepsSaveAllFail'));
      }
    }
  };

  const deleteAllSteps = async () => {
    try {
      if ((!grant || !grant.grant_steps) && grantSteps.length === 0) {
        message.error(t('grant_form|no_grant_steps_to_delete'));
        return;
      }
      const grantStepIds = [
        ...grantSteps
          .filter((step) => step.grant_step_id !== '')
          .map((step) => step.grant_step_id),
      ];
      setIsDeletingAll(true);
      if (grantStepIds.length > 0) {
        await dispatch(deleteAllGrantSteps({ api: newApi, grantStepIds }));
      }
      setGrantSteps([]);
      message.success(t('grant_form|grant_steps_delete_success'));
    } catch (e: any) {
      console.error(e);
      message.error(t('grant_form|grant_steps_delete_fail'));
    }
    setIsDeletingAll(false);
  };

  const handleDeleteGrantStep = async (grant_step_id: string) => {
    const newGrantsSteps = grantSteps.filter(
      (step, _) => step.grant_step_id !== grant_step_id,
    );
    setGrantSteps(newGrantsSteps);

    if (grant_step_id !== '') {
      try {
        await dispatch(
          deleteGrantStep({
            api: newApi,
            grantStepId: grant_step_id,
            grantId: grant?.grant_id!,
          }),
        );
        message.success(t('grant_form|grant_steps_delete_success'));
      } catch (e: any) {
        console.error(e);
        message.error(t('grant_form|grant_steps_delete_fail'));
      }
    }
  };

  const displayGrantSteps = (steps: GrantStep[]) => {
    return isGeneratingSteps ? (
      <Loading />
    ) : (
      steps.map((grantStep, index) => (
        <GrantFormStep
          key={index}
          grantStep={grantStep}
          index={index}
          handleDeleteGrantStep={() =>
            handleDeleteGrantStep(grantStep.grant_step_id)
          }
          onChangeStep={handleChangeGrantStep}
          isAllGenerated={isAllGenerated}
        />
      ))
    );
  };

  const handleAddGrantStep = async () => {
    try {
      var newGrantStep: GrantStep = {
        grant_step_id: '',
        grant_id: grant?.grant_id!,
        display_order: grantSteps.length,
        title_en: '',
        title_fr: '',
        description_en: '',
        description_fr: '',
      };
      const newReceivedGrant = await dispatch(
        createGrantStep({ api: newApi, grantStep: newGrantStep }),
      ).unwrap();

      const newGrantSteps = [...grantSteps, newReceivedGrant];
      setGrantSteps(newGrantSteps);

      message.success(t('grant_form|grant_step_add_success'));
    } catch (e: any) {
      console.error(e);
      message.error(t('grant_form|grant_step_add_fail'));
    }
  };

  const toggleIsDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleReorderGrantSteps = async (reorderedItems: GrantStep[]) => {
    try {
      const stepIdMap = reorderedItems.map(
        (grantStep) => grantStep.grant_step_id,
      );
      await dispatch(
        reorderGrantSteps({
          api: newApi,
          reorderedGrantStepIds: stepIdMap,
        }),
      ).unwrap();

      const reorderedGrantSteps = stepIdMap
        .map((id) => grantSteps.find((step) => step.grant_step_id === id))
        .filter((step): step is GrantStep => step !== undefined);
      setGrantSteps(reorderedGrantSteps);

      message.success(t('grant_form|grant_steps_reorder_success'));
    } catch (error) {
      message.error(t('grant_form|grant_steps_reorder_fail'));
    }
  };

  const handleGenerateSteps = async () => {
    try {
      if (!!grant) {
        const steps = await dispatch(
          generateGrantSteps({ api, grantId: grant.grant_id }),
        ).unwrap();
        if (steps.length > 0) {
          setGrantSteps(steps);
          message.success(t('grant_form|generateStepsSuccess'));
        } else {
          message.info(t('grant_form|noStepsFound'));
        }
      }
    } catch (error) {
      message.error(t('grant_form|generateStepsFail'));
    }
  };

  const handleChangeGrantStep = (grantStep: GrantStep) => {
    const updatedSteps: GrantStep[] = grantSteps.map((step) =>
      step.grant_step_id === grantStep.grant_step_id ? grantStep : step,
    );

    setGrantSteps(updatedSteps);
  };

  return (
    <>
      <Div flex="column" gap={16}>
        <Typography.Title level={5}>
          {t('programs_admin|grant_steps')}
        </Typography.Title>
        <Div flex="row" justify="space-between">
          <Button
            onClick={handleGenerateSteps}
            size="small"
            style={{ minWidth: 140 }}
            defaultStyle={theme.colors.purple_1}
            disabled={isGeneratingSteps}
          >
            {t('grant_form|generateSteps')}
          </Button>
          <Div flex="row" gap={8} fitContent>
            <Button
              onClick={toggleIsDrawerOpen}
              size="small"
              style={{ minWidth: 140 }}
              defaultStyle={
                isDrawerOpen ? theme.colors.white_1 : theme.colors.grey_1
              }
              headingIcon={<Edit size={16} />}
              disabled={isGeneratingSteps || isAllGenerated}
            >
              {isDrawerOpen ? t('button|cancel') : t('grant_form|edit_order')}
            </Button>
            <AntdButton
              loading={saveAllFlag}
              disabled={
                saveAllFlag ||
                (!isSomeDirty && !isAllGenerated) ||
                isGeneratingSteps
              }
              onClick={saveAllSteps}
            >
              {t('grant_form|save_all')}
            </AntdButton>
            <Popconfirm
              title={t('grant_form|grant_steps_delete_all')}
              description={t('grant_form|grant_steps_delete_all_long')}
              onConfirm={deleteAllSteps}
              okText={t('grant_form|delete')}
              cancelText={t('grant_form|no')}
            >
              <AntdButton
                type="primary"
                loading={isDeletingAll}
                disabled={
                  isDeletingAll || grantSteps.length === 0 || isGeneratingSteps
                }
                danger={true}
                icon={<Delete size={14} />}
              >
                {t('grant_form|delete_all')}
              </AntdButton>
            </Popconfirm>
          </Div>
        </Div>
        <Div flex="column" gap={16} style={{ maxWidth: '100%' }}>
          <Form.Item name={'grant_steps'}>
            {displayGrantSteps(grantSteps)}
            <AntdButton
              onClick={handleAddGrantStep}
              type="text"
              size="middle"
              icon={<AddNew size={24} />}
              disabled={isGeneratingSteps}
            />
          </Form.Item>
        </Div>
      </Div>
      <GrantFormStepsDrawer
        open={isDrawerOpen}
        handleCancel={toggleIsDrawerOpen}
        grantSteps={grantSteps}
        onDeleteGrantStep={handleDeleteGrantStep}
        onReorderGrantSteps={handleReorderGrantSteps}
      />
    </>
  );
};

export default GrantFormSteps;
