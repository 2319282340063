import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { useTranslation } from '../../../../plugins/i18n';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
const SelectProvince = ({ onChange, isRequired, isCanada, disabled, }) => {
    const { t } = useTranslation();
    return (_jsx(FormItem, { layout: "vertical", label: _jsx(FormLabel, { label: t(`profile|profileForm.address.labels.${isCanada ? 'province' : 'state'}`) }), name: "province", rules: [
            {
                required: isRequired,
                message: t(`profile|profileForm.address.validation.${isCanada ? 'province' : 'state'}`),
            },
        ], style: { minWidth: 175 }, children: isCanada ? (_jsxs(Select, { placeholder: t(`profile|profileForm.address.extra.selectProvince`), onChange: onChange, size: "large", disabled: disabled, children: [_jsx(Select.Option, { value: "ON", children: "ON" }), _jsx(Select.Option, { value: "QC", children: "QC" }), _jsx(Select.Option, { value: "NS", children: "NS" }), _jsx(Select.Option, { value: "NB", children: "NB" }), _jsx(Select.Option, { value: "MB", children: "MB" }), _jsx(Select.Option, { value: "BC", children: "BC" }), _jsx(Select.Option, { value: "PE", children: "PE" }), _jsx(Select.Option, { value: "SK", children: "SK" }), _jsx(Select.Option, { value: "AB", children: "AB" }), _jsx(Select.Option, { value: "NL", children: "NL" })] })) : (_jsx(Input, { size: "large", onChange: onChange, disabled: disabled })) }));
};
SelectProvince.Demo = () => {
    return _jsx(SelectProvince, { isCanada: true, disabled: true });
};
export default SelectProvince;
