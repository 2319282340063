import {
  ApiError,
  ApiErrorInitialState,
  GrantStep,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApi from '../admin-api';
import { generateGrantSteps } from './grants-slice';

const grantStepsAdapter = createEntityAdapter({
  selectId: (model: GrantStep) => model.grant_step_id,
});

export interface GrantStepState {
  status: 'idle' | 'pending';
  error: ApiError;
  grantSteps: EntityState<GrantStep, string>;
  dirtyMap: Record<string, boolean>;
  areAllGenerated: boolean;
  // First key : grantStepId, second key: sectionName
  loadingMap: Record<string, Record<string, boolean>>;
}

const initialState: GrantStepState = {
  status: 'idle',
  error: ApiErrorInitialState,
  grantSteps: grantStepsAdapter.getInitialState(),
  dirtyMap: {},
  areAllGenerated: false,
  loadingMap: {},
};

export const fetchGrantStepsForProgram = createAsyncThunk<
  GrantStep[],
  { api: AdminApi; grantId: string; programId?: string },
  { rejectValue: ApiError }
>(
  'admin/fetchGrantStepsForProgram',
  async (
    {
      api,
      grantId,
      programId,
    }: { api: AdminApi; grantId: string; programId?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.get<GrantStep[]>(
        `/grant-steps/${grantId}?programId=${programId}`,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createGrantStep = createAsyncThunk<
  GrantStep,
  { api: AdminApi; grantStep: GrantStep },
  { rejectValue: ApiError }
>(
  'admin/createGrantStep',
  async (
    { api, grantStep }: { api: AdminApi; grantStep: GrantStep },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<GrantStep>(`/grant-steps`, grantStep);
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateGrantStep = createAsyncThunk<
  GrantStep,
  { api: AdminApi; grantStep: GrantStep },
  { rejectValue: ApiError }
>(
  'admin/updateGrantStep',
  async (
    { api, grantStep }: { api: AdminApi; grantStep: GrantStep },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.put<GrantStep>(
        `/grant-steps/single`,
        grantStep,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateGrantSteps = createAsyncThunk<
  GrantStep[],
  { api: AdminApi; grantSteps: GrantStep[] },
  { rejectValue: ApiError }
>(
  'admin/updateGrantSteps',
  async (
    { api, grantSteps }: { api: AdminApi; grantSteps: GrantStep[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.put<GrantStep[]>(`/grant-steps`, grantSteps);
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteGrantStep = createAsyncThunk<
  string,
  { api: AdminApi; grantStepId: string; grantId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteGrantStep',
  async (
    {
      api,
      grantStepId,
      grantId,
    }: { api: AdminApi; grantStepId: string; grantId: string },
    { rejectWithValue },
  ) => {
    try {
      await api.delete<string>(
        `/grant-steps/${grantStepId}?grantId=${grantId}`,
      );
      return grantStepId;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAllGrantSteps = createAsyncThunk<
  string[],
  { api: AdminApi; grantStepIds: string[] },
  { rejectValue: ApiError }
>(
  'admin/deleteGrantSteps',
  async (
    { api, grantStepIds }: { api: AdminApi; grantStepIds: string[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<string[]>(
        `/grant-steps/delete`,
        grantStepIds,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const translateStep = createAsyncThunk<
  { stepID: string; section: keyof GrantStep; newContent: string },
  {
    api: AdminApi;
    stepID: string;
    targetLocale: string;
    otherContent: string;
    section: keyof GrantStep;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/translateStep',
  async (
    {
      api,
      stepID,
      targetLocale,
      otherContent,
      section,
    }: {
      api: AdminApi;
      stepID: string;
      targetLocale: string;
      otherContent: string;
      section: keyof GrantStep;
    },
    { rejectWithValue },
  ) => {
    try {
      const newContent = (
        await api.put<string>(
          `/chats/translate/${targetLocale}?section=${section}`,
          otherContent,
        )
      ).data;
      return { stepID, section, newContent };
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const reorderGrantSteps = createAsyncThunk<
  string[],
  { api: AdminApi; reorderedGrantStepIds: string[] },
  { rejectValue: ApiError }
>(
  'admin/reorderGrantSteps',
  async (
    {
      api,
      reorderedGrantStepIds,
    }: { api: AdminApi; reorderedGrantStepIds: string[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.put<string[]>(
        `/grant-steps/reorder`,
        reorderedGrantStepIds,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleGrantStepValidation = createAsyncThunk<
  GrantStep[],
  { api: AdminApi; grantId: string; programId: string; grantStepId: string },
  { rejectValue: ApiError }
>(
  'client/toggleGrantStepValidation',
  async (
    {
      api,
      grantId,
      programId,
      grantStepId,
    }: {
      api: AdminApi;
      grantId: string;
      programId: string;
      grantStepId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api
        .createAxios()
        .put<
          GrantStep[]
        >(`/grant-steps/validate/${programId}/${grantId}/${grantStepId}`);
      return response.data;
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const overwriteGrantSteps = createAsyncThunk<
  string[],
  { api: AdminApi; grantSteps: GrantStep[] },
  { rejectValue: ApiError }
>(
  'admin/overwriteGrantSteps',
  async (
    { api, grantSteps }: { api: AdminApi; grantSteps: GrantStep[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post<string[]>(
        `/grant-steps/overwrite`,
        grantSteps,
      );
      return response.data;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const grantStepsSlice = createSlice({
  name: 'grantSteps',
  initialState,
  reducers: {
    setIsGrantStepDirty: (
      state,
      action: PayloadAction<{ grantStepId: string }>,
    ) => {
      const { grantStepId } = action.payload;
      state.dirtyMap[grantStepId] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGrantStepsForProgram.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchGrantStepsForProgram.fulfilled,
      (state, { payload }) => {
        if (!!payload) {
          state.grantSteps = grantStepsAdapter.setAll(
            state.grantSteps,
            payload,
          );
        } else {
          state.grantSteps = grantStepsAdapter.getInitialState();
        }
        state.status = 'idle';
      },
    );
    builder.addCase(
      fetchGrantStepsForProgram.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(createGrantStep.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createGrantStep.fulfilled, (state, { payload }) => {
      state.status = 'idle';
    });
    builder.addCase(createGrantStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateGrantStep.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateGrantStep.fulfilled, (state, { payload }) => {
      state.dirtyMap[payload.grant_step_id] = false;
      state.status = 'idle';
    });
    builder.addCase(updateGrantStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateGrantSteps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateGrantSteps.fulfilled, (state, { payload }) => {
      state.dirtyMap = {};
      state.status = 'idle';
    });
    builder.addCase(updateGrantSteps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteGrantStep.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteGrantStep.fulfilled, (state, { payload }) => {
      state.dirtyMap[payload] = false;
      state.status = 'idle';
    });
    builder.addCase(deleteGrantStep.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteAllGrantSteps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteAllGrantSteps.fulfilled, (state, { payload }) => {
      state.dirtyMap = {};
      state.status = 'idle';
    });
    builder.addCase(deleteAllGrantSteps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(translateStep.pending, (state, action) => {
      const { stepID, section } = action.meta.arg;
      if (!state.loadingMap[stepID]) {
        state.loadingMap[stepID] = {};
      }
      state.loadingMap[stepID][section] = true;

      state.status = 'pending';
    });
    builder.addCase(translateStep.rejected, (state, action) => {
      const { stepID, section } = action.meta.arg;
      state.loadingMap[stepID][section] = false;

      state.error = action.payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(translateStep.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      state.loadingMap[payload.stepID][payload.section] = false;
    });
    builder.addCase(reorderGrantSteps.pending, (state, action) => {
      state.status = 'pending';
    });
    builder.addCase(reorderGrantSteps.rejected, (state, action) => {
      state.error = action.payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(reorderGrantSteps.fulfilled, (state) => {
      state.status = 'idle';
    });
    builder.addCase(toggleGrantStepValidation.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      toggleGrantStepValidation.fulfilled,
      (state, { payload }) => {
        state.grantSteps = grantStepsAdapter.setAll(state.grantSteps, payload);
        state.status = 'idle';
      },
    );
    builder.addCase(
      toggleGrantStepValidation.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(generateGrantSteps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(generateGrantSteps.fulfilled, (state) => {
      state.dirtyMap = {};
      state.areAllGenerated = true;
      state.status = 'idle';
    });
    builder.addCase(generateGrantSteps.rejected, (state, action) => {
      state.error = action.payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(overwriteGrantSteps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(overwriteGrantSteps.fulfilled, (state) => {
      state.dirtyMap = {};
      state.areAllGenerated = false;
      state.status = 'idle';
    });
    builder.addCase(overwriteGrantSteps.rejected, (state, action) => {
      state.error = action.payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const selectGrantStepsLoading = (state: RootState) =>
  state.grantProjects.status === 'pending';

export const selectError = (state: RootState) =>
  state.grantSteps.error.error_code;

export const selectIsLoading = (state: RootState) =>
  state.grantSteps.status === 'pending';

export const selectIsSomeDirty = (state: RootState) =>
  Object.values(state.grantSteps.dirtyMap).some((status) => status);

export const selectIsAllGenerated = (state: RootState) =>
  state.grantSteps.areAllGenerated;

export const selectIsDirty = (state: RootState, id: string) =>
  state.grantSteps.dirtyMap[id] ?? false;

export const selectIsLoadingSolo = createSelector(
  [
    (state: RootState, grantStepId: string, _: string) =>
      state.grantSteps.loadingMap[grantStepId] ?? {},
    (_: RootState, __: string, sectionName: string) => sectionName,
  ],
  (loadingMapForStep, sectionName) => loadingMapForStep[sectionName] ?? false,
);

export const {
  selectAll: selectAllGrantSteps,
  selectById: selectGrantStepById,
} = grantStepsAdapter.getSelectors(
  (state: RootState) => state.grantSteps.grantSteps,
);

export const { setIsGrantStepDirty } = grantStepsSlice.actions;

export const grantStepsReducer = grantStepsSlice.reducer;
