import styled from '../../../../plugins/styled';
export const StyledCustomInput = styled.input `
  font-family: Inter;
  background: ${({ theme }) => theme.colors.white_1};
  color: ${({ theme }) => theme.colors.grey_2};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 4px;
  min-width: 450px;
  width: 100%;

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #2b2b2b;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xxl}px) {
    min-width: auto;
  }
`;
