import { useMemo } from 'react';
import ProfileBlocks from '../../../../../features/enums/profile-blocks';
import AboutForm from '../about-form';
import FastInfosForm from '../fast-infos-form';
import PortfolioForm from '../portfolio-form';
import ProfileAssetsForm from '../profile-assets-form';
import TeamEdit from '../team/team-edit';
const useProfileEditModalBlocks = (props) => {
    const { modal: { type }, } = props;
    const blocks = useMemo(() => {
        const { modal: { targetId }, teamMembers, activePortfolio, categoriesWithSelectedTags, setTeamMembers, } = props;
        const profileBlocks = {
            [ProfileBlocks.About]: {
                Component: AboutForm,
                size: 'large',
            },
            [ProfileBlocks.FastInfos]: {
                Component: FastInfosForm,
                size: 'medium',
            },
            [ProfileBlocks.Team]: {
                Component: TeamEdit,
                additionalProps: {
                    activeMemberId: targetId,
                    teamMembers,
                    setTeamMembers,
                },
                size: 'large',
            },
            [ProfileBlocks.Portfolios]: {
                Component: PortfolioForm,
                additionalProps: {
                    activePortfolio,
                    categoriesWithSelectedTags,
                },
                size: 'large',
            },
            [ProfileBlocks.Presentation]: {
                Component: ProfileAssetsForm,
                additionalProps: {
                    type: 'video',
                },
                size: 'large',
            },
            [ProfileBlocks.Banner]: {
                Component: ProfileAssetsForm,
                additionalProps: {
                    type: 'banner',
                },
                size: 'medium',
            },
            [ProfileBlocks.Logo]: {
                Component: ProfileAssetsForm,
                additionalProps: {
                    type: 'logo',
                },
                size: 'medium',
            },
            [ProfileBlocks.Reviews]: undefined,
            [ProfileBlocks.Rfp]: undefined,
            [ProfileBlocks.Contact]: undefined,
            [ProfileBlocks.AdBanner]: undefined,
            [ProfileBlocks.Expertise]: undefined,
        };
        return profileBlocks;
    }, [props]);
    return useMemo(() => {
        return blocks[type];
    }, [blocks, type]);
};
export default useProfileEditModalBlocks;
