import Tabs from 'antd/es/tabs';
import styled, { css } from '../../../plugins/styled';
const ContainerStyling = css `
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: ${({ theme }) => theme.colors.white_2};
`;
const ContainerSizeStyling = css `
  width: 100%;
`;
export const ContainerTabsStyled = styled(Tabs) `
  .ant-tabs-nav {
    margin: 0;
    ${({ $fullWidth }) => (!$fullWidth ? ContainerSizeStyling : undefined)}
    .ant-tabs-tab {
      background: ${({ theme }) => theme.colors.beige_1};
      border: 1px solid ${({ theme }) => theme.colors.grey_5};
      border-bottom: none;
      &.ant-tabs-tab-active {
        background: ${({ theme }) => theme.colors.white_2};
      }
    }
  }
  .ant-tabs-content {
    ${({ $fullWidth }) => (!$fullWidth ? ContainerSizeStyling : undefined)}
    ${ContainerStyling}
    padding: 16px 24px;

    border: 1px solid ${({ theme }) => theme.colors.grey_5};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      padding: 16px 52px;
    }
  }
`;
export const ContainerStyled = styled.section `
  ${({ $fullWidth }) => (!$fullWidth ? ContainerSizeStyling : undefined)}
  ${ContainerStyling}

  overflow: ${({ $overflow }) => $overflow !== null && $overflow !== void 0 ? $overflow : 'hidden'};
  padding: ${({ $noPadding }) => (!$noPadding ? '48px 24px' : 0)};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: ${({ $noPadding }) => (!$noPadding ? '35px 52px' : 0)};
  }

  @media (min-width: 1600px) {
    padding: ${({ $noPadding }) => (!$noPadding ? '35px 52px' : 0)};
  }
`;
export const ContainerFullWidthHeaderStyling = styled.div `
  position: sticky;
  top: -1px;
  z-index: 1000;

  width: calc(100% + 24px + 24px);
  margin-left: -24px;
  padding: 0 24px;

  &.is-pinned {
    background-color: ${({ theme }) => theme.colors.white_1}CC;
    backdrop-filter: blur(5px);
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey_5};
    padding: 12px 24px;
    transition: background-color 200ms linear;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% + 52px + 52px);
    margin-left: -52px;
    padding: 0 52px;
    &.is-pinned {
      padding: 12px 52px;
    }
  }
`;
