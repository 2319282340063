import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from 'react';
import { AddressInformationsInitialState, } from '../../../../features/entities';
import { useTranslation } from '../../../../plugins/i18n';
import { StyledCustomInput } from '../../form-items/custom-input/styles';
import { getLongAddress, getShortAddress } from '../../utils/get-address';
const GoogleAutocomplete = ({ form, setCanada, disabled, }) => {
    const { t } = useTranslation();
    const input = useRef(null);
    const [value, setValue] = useState(form.getFieldValue('complete'));
    useEffect(() => {
        setTimeout(() => {
            setValue(form.getFieldValue('complete'));
        }, 500);
    }, []);
    const onPlaceSelected = (address, geometry) => {
        var _a, _b;
        if (!!address) {
            const addressInfos = address.reduce((o, value) => {
                return Object.assign(Object.assign({}, o), { [value.types[0]]: ['administrative_area_level_1'].includes(value.types[0])
                        ? value.short_name
                        : value.long_name });
            }, AddressInformationsInitialState);
            const short = getShortAddress(addressInfos);
            const complete = getLongAddress(short, {
                city: addressInfos.locality,
                address: short,
                country: addressInfos.country,
                postal_code: addressInfos.postal_code,
                province: addressInfos.administrative_area_level_1,
            });
            setValue(complete);
            setCanada(addressInfos.country == 'Canada');
            form.setFieldsValue({
                address: getShortAddress(addressInfos),
                city: addressInfos.locality,
                country: addressInfos.country,
                isCanada: addressInfos.country == 'Canada',
                province: addressInfos.administrative_area_level_1,
                postal_code: addressInfos.postal_code,
                lng: (_a = geometry.location) === null || _a === void 0 ? void 0 : _a.lng(),
                lat: (_b = geometry.location) === null || _b === void 0 ? void 0 : _b.lat(),
                complete,
            });
        }
    };
    useEffect(() => {
        if (!input.current)
            return;
        const center = { lat: 50.064192, lng: -130.605469 };
        const defaultBounds = {
            north: center.lat + 0.1,
            south: center.lat - 0.1,
            east: center.lng + 0.1,
            west: center.lng - 0.1,
        };
        const options = {
            bounds: defaultBounds,
            fields: ['address_components', 'geometry', 'icon', 'name'],
            strictBounds: false,
            componentRestrictions: { country: ['ca', 'us', 'fr'] },
            types: ['street_address', 'locality'],
        };
        const autocomplete = new google.maps.places.Autocomplete(input.current, options);
        autocomplete === null || autocomplete === void 0 ? void 0 : autocomplete.setFields(['place_id', 'geometry', 'name']);
        autocomplete.addListener('place_changed', async () => {
            var _a, _b;
            const place = autocomplete.getPlace();
            onPlaceSelected((_a = place.address_components) !== null && _a !== void 0 ? _a : [], (_b = place.geometry) !== null && _b !== void 0 ? _b : {});
        });
    }, [input]);
    const onChange = ({ target: { value } }) => {
        form.setFieldValue('complete', value);
        setValue(value);
    };
    return (_jsx(StyledCustomInput, { className: "ant-input ant-input-lg", ref: input, onChange: onChange, value: value, disabled: disabled, placeholder: t('profile|profileForm.address.placeholder.addressComplete') }));
};
export default GoogleAutocomplete;
